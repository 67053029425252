<template>
  <LayoutPage title="Reportes de rendiciones">
    <!-- filtros table  -->
    <v-expansion-panels v-model="panel" class="mb-3">
      <v-expansion-panel :key="0">
        <v-expansion-panel-header class="outline_none" disable-icon-rotate>
          <template v-slot:actions>
            <span class="primary--text text-subtitle-1 mt-0">Filtros</span>
            <v-icon size="33" color="success"> search </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 1 fila de filtros -->
          <v-row class="pt-0 ">
            <v-col cols="12" md="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaInicio)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  locale="es-ar"
                  no-title
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="
                    {
                      page = 1;
                      getData();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de fin"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaFin)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  locale="es-ar"
                  no-title
                  scrollable
                  :min="fechaInicio"
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="
                    {
                      page = 1;
                      getData();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--end filtros-->

    <div id="pdf-preview"></div>

    <v-data-table
      :headers="headers"
      :items="rendiciones"
      :loading="loading"
      no-data-text="Sin registros para mostrar"
      loading-text="Cargando... espere por favor!"
      hide-default-footer
      @page-count="pageCount = $event"
      :page.sync="page"
      :items-per-page="rowsPerPage"
    >
      <template v-slot:[`item.Nombre`]="{ item }">
        {{ getNombreArchivo(item.Nombre) + ".csv" }}
      </template>
      <template v-slot:[`item.TotalCobrado`]="{ item }">
        {{ formatImportFloat(item.TotalCobrado) }}
      </template>
      <template v-slot:[`item.TotalRendido`]="{ item }">
        {{ formatImportFloat(item.TotalRendido) }}
      </template>
      <template v-slot:[`item.TotalRendidoSplit`]="{ item }">
        {{ formatImportFloat(item.TotalRendidoSplit) }}
      </template>
      <template v-slot:[`item.PorcentajeSplit`]="{ item }">
        {{ item.PorcentajeSplit }}%
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="green darken-3"
              @click="downloadExcel(item)"
              v-on="on"
              ><v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Descargar Excel</span>
        </v-tooltip>
        <v-tooltip bottom> </v-tooltip>
        <!-- descargar pdf -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="red darken-3"
              @click="downloadPdf(item)"
              v-on="on"
              ><v-icon>mdi-file-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar PDF</span>
        </v-tooltip>

        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="grey darken-3"
              @click="showPreview(item)"
              v-on="on"
              ><v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Vista previa</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <v-row class="no-gutters mt-2 mx-2">
          <v-flex xs12>
            <span>Mostrar</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark text color="primary" class="ml-2" v-on="on">
                  {{ rowsPerPage }}
                  <v-icon>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in rowsPerPageArray"
                  :key="index"
                  @click="rowsPerPage = number"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-row>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <!-- PREVIEW REPORTE -->
    <v-dialog
      v-model="preview"
      max-width="1450px"
      class="white"
      style="z-index: 14000 !important;"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-bold ">
          REPORTE DE RENDICIONES
          <v-spacer />
          <span class="body-1 grey--text text--darken-3 ">
            <span class="font-weight-bold">Fecha de depósito:</span>
            {{ previewData.fecha }}
          </span>
        </v-card-title>
        <v-divider class="ma-0"></v-divider>

        <v-data-table
          :headers="headerPreview"
          :items="previewData.items"
          hide-default-footer
          disable-pagination
          :item-class="reversionColor"
          disable-sort
          no-data-text="Sin registros para mostrar"
        >
          <template v-slot:[`item.FechaCobro`]="{ item }">
            {{ item.FechaCobro ? item.FechaCobro : "-" }}
          </template>
          <template v-slot:[`item.ImporteCobrado`]="{ item }">
            {{ item.ImporteCobrado ? "$ " + item.ImporteCobrado : "-" }}
          </template>
          <template v-slot:[`item.ImporteDepositado`]="{ item }">
            $ {{ item.ImporteDepositado }}
          </template>
          <template v-slot:[`item.ImporteSplit`]="{ item }">
            {{ item.ImporteSplit != "" ? "$ " + item.ImporteSplit : "-" }}
          </template>
          <template v-slot:[`item.PorcentajeSplit`]="{ item }">
            {{ item.PorcentajeSplit != "" ? item.PorcentajeSplit + "% " : "-" }}
          </template>
          <template v-slot:[`item.Comision`]="{ item }">
            {{ item.Comision ? "$ " + item.Comision : "-" }}
          </template>
          <template v-slot:[`item.CostoServicio`]="{ item }">
            {{ item.CostoServicio ? "$ " + item.CostoServicio : "-" }}
          </template>
          <template v-slot:[`item.Iva`]="{ item }">
            <span class="w-[180px]">
              {{ item.Iva ? "$ " + item.Iva : "-" }}</span
            >
          </template>

          <template v-slot:[`header.Iva`]="{ header }">
            <span :style="{ width: '50px', display: 'inline-block' }">{{
              header.value
            }}</span>
          </template>

          <template v-slot:[`item.Retenciones`]="{ item }">
            {{
              item.Retenciones && item.Retenciones != "0,00"
                ? "$ " + item.Retenciones
                : "-"
            }}
          </template>
        </v-data-table>

        <p
          class="font-bold mx-4 mt-6 mb-3 text-start text-lg"
          v-show="previewData.devoluciones.length > 0"
        >
          DESCUENTOS
        </p>

        <v-data-table
          :headers="headerDevolucion"
          :items="previewData.devoluciones"
          hide-default-footer
          disable-pagination
          disable-sort
          no-data-text="Sin registros para mostrar"
          v-show="previewData.devoluciones.length > 0"
          class="mb-6"
        >
        </v-data-table>

        <v-divider class="ma-0 "></v-divider>

        <!-- MOSTRANDO TOTAL COBRADO -->
        <v-row justify="end" align="center" no-gutters class="px-3 pt-4 pb-1">
          <v-chip
            :ripple="false"
            color="blue lighten-1"
            class="white--text body-1 "
          >
            Total cobrado:
            <span class="font-weight-bold ml-2">
              {{ formatImportFloat(previewData.totalCobrado) }}
            </span>
          </v-chip>
        </v-row>

        <!-- MOSTRANDO TOTAL COMISIONES -->
        <v-row justify="end" align="center" no-gutters class="px-3 pt-3 pb-1">
          <v-chip :ripple="false" color="grey lighten-2 mr-4">
            Total comisiones:
            <span class="font-weight-bold ml-2">
              {{ formatImportFloat(previewData.totalComision) }}
            </span>
          </v-chip>
          <!-- TOTAL IVA -->
          <v-chip :ripple="false" color="grey lighten-2 ">
            Total iva:
            <span class="font-weight-bold ml-2">
              {{ formatImportFloat(previewData.totalIva) }}
            </span>
          </v-chip>

          <v-chip
            :ripple="false"
            color="grey lighten-2 ml-4"
            v-if="costoServicio"
          >
            Cargo por servicio:
            <span class="font-weight-bold ml-2">
              {{ formatImportFloat(previewData.totalCostoServicio) }}
            </span>
          </v-chip>
        </v-row>

        <!-- MOSTRANDO TOTAL REVERTIDO -->
        <v-row
          justify="end"
          align="center"
          no-gutters
          class="px-3 pb-3 pt-1"
          v-if="previewData.totalReversion !== 0"
        >
          <v-chip
            :ripple="false"
            color="red lighten-1"
            class="white--text body-1"
          >
            Total revertido:
            <span class="font-weight-bold ml-2">
              {{ formatImportFloat(previewData.totalReversion) }}
            </span>
          </v-chip>
        </v-row>

        <!-- ----------------MOSTRANDO TOTALES RETENCIONES---------------------------------------- -->
        <v-row
          justify="end"
          align="center"
          no-gutters
          class="px-3 pb-3 pt-1"
          v-show="
            previewData.totalRetGanancias !== '' &&
              previewData.totalRetGanancias !== '0,00'
          "
        >
          <v-chip :ripple="false" color="grey lighten-2">
            Ret. Gcia RG 4622/2019:
            <span class="font-weight-bold ml-2">
              $ {{ formatImportFloat(previewData.totalRetGanancias) }}
            </span>
          </v-chip>
        </v-row>

        <v-row
          justify="end"
          align="center"
          no-gutters
          class="px-3 pb-3 pt-1"
          v-show="
            previewData.totalRetIVA !== '' && previewData.totalRetIVA !== '0,00'
          "
        >
          <v-chip :ripple="false" color="grey lighten-2">
            Ret. IVA RG 4622/2019:
            <span class="font-weight-bold ml-2">
              $ {{ formatImportFloat(previewData.totalRetIVA) }}
            </span>
          </v-chip>
        </v-row>

        <v-row
          justify="end"
          align="center"
          no-gutters
          class="px-3 pb-3 pt-1"
          v-show="
            previewData.totalRetIIBB !== '' &&
              previewData.totalRetIIBB !== '0,00'
          "
        >
          <v-chip :ripple="false" color="grey lighten-2">
            Ret IIBB RG 202/2020:
            <span class="font-weight-bold ml-2">
              $ {{ formatImportFloat(previewData.totalRetIIBB) }}
            </span>
          </v-chip>
        </v-row>
        <!-- MOSTRANDO TOTAL DESCUENTO -->
        <v-row
          justify="end"
          align="center"
          no-gutters
          class="px-3 pb-3 pt-1"
          v-show="previewData.devoluciones.length > 0"
        >
          <v-chip
            :ripple="false"
            color="green lighten-1"
            class="white--text body-1"
          >
            Total descuento:
            <span class="font-weight-bold ml-2">
              {{ formatImportFloat(previewData.totalDevolucion) }}
            </span>
          </v-chip>
        </v-row>

        <!-- MOSTRANDO TOTAL RENDIDO o TOTAL RENDIDO DE SPLIT -->
        <v-row justify="end" align="center" no-gutters class="px-3 pb-3 pt-1">
          <v-chip
            :ripple="false"
            color="green darken-3"
            class="white--text body-1"
          >
            Total rendido:
            <span class="font-weight-bold ml-2">
              {{
                band
                  ? formatImportFloat(previewData.totalRendidoSplit)
                  : formatImportFloat(previewData.totalRendido)
              }}

              <!-- {{ formatImportFloat(previewData.totalRendido) }} -->
            </span>
          </v-chip>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Modal de Fechas -->
    <v-dialog v-model="RestriccionFecha" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          <v-col>
            <v-row
              ><div class="flex justify-center">
                <v-icon x-large color="red">mdi-close-octagon</v-icon>
              </div></v-row
            >
            <v-row></v-row>
            <v-row>
              <div class="flex justify-center">
                <h1>Rango de Fechas</h1>
              </div>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text>No puede ser mayor a 31 dias</v-card-text>
        <v-card-actions>
          <div class="flex justify-center">
            <v-btn
              color="green darken-1"
              class="text-white"
              small
              @click="closeModal"
            >
              Aceptar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </LayoutPage>
</template>

<script>
import moment from "moment";
import LayoutPage from "../../../components/LayoutPage.vue";
import formatDate from "../../../utils/formatDate";
import formatImport from "../../../utils/formatImport";
import reportesService from "../../../services/reportes";
import { mapGetters } from "vuex";
import FileSaver from "file-saver";
import { formatImportFloat } from "../../../utils/formatImport";

import ExcelJS from "exceljs";
import saveAs from "file-saver";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PDFObject from "pdfobject";
// import Papa from "papaparse";
import { dataEncabezadoPDF } from "@/utils/dataEncabezadoPDF";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  name: "ReporteRendiciones",
  components: { LayoutPage },
  data: () => ({
    panel: 0,
    fechaInicio: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),
    fechaFin: moment().format("YYYY-MM-DD"),
    RestriccionFecha: false,
    menu1: false,
    menu2: false,
    preview: false,
    previewData: {
      fecha: null,
      items: [],
      devoluciones: [],
      totalCobrado: 0,
      totalRendido: 0,
      totalRendidoSplit: 0,
      porcentajeSplit: 0,
      totalIva: 0,
      totalComision: 0,
      totalReversion: 0,
      totalRetGanancias: "",
      totalRetIVA: "",
      totalRetIIBB: "",
      totalDevolucion: "",
    },
    //Header de Detalle
    headerPreviewD: [
      { text: "Cuenta", value: "Cuenta" },
      { text: "Referencia", value: "Id" },
      { text: "Concepto", value: "Concepto" },
      { text: "Fecha de cobro", value: "FechaCobro" },
      { text: "Fecha de deposito", value: "FechaDeposito" },
      { text: "Importe cobrado", value: "ImporteCobrado" },
      { text: "Comisión", value: "Comision" },
      { text: "IVA", value: "Iva" },
      { text: "Cargo por servicio", value: "CostoServicio" },
      { text: "Importe depositado", value: "ImporteDepositado" },
      { text: "Importe Porcentaje Split", value: "PorcentajeSplit" },
      { text: "Importe Rendido Split", value: "ImporteSplit" },
      { text: "Cant. boletas", value: "CantidadBoletasCobradas" },
    ],

    headerDevolucion: [
      { text: "Fecha de depósito", value: "FechaDeposito" },
      { text: "Referencia", value: "ReferenciaExterna" },
      { text: "Importe depositado", value: "ImporteDepositadoLabel" },
      { text: "Motivo", value: "Motivo" },
    ],

    //Header de Tabla Principal
    headersG: [
      { text: "FECHA", value: "Fecha", sortable: false },
      { text: "NOMBRE", value: "Nombre", sortable: false },

      { text: "IMPORTE COBRADO", value: "TotalCobrado", sortable: false },
      { text: "IMPORTE RENDIDO", value: "TotalRendido", sortable: false },
      {
        text: "IMPORTE RENDIDO SPLIT",
        value: "TotalRendidoSplit",
        sortable: false,
      },
      {
        text: "IMPORTE PORCENTAJE SPLIT",
        value: "PorcentajeSplit",
        sortable: false,
      },
      {
        text: "REGISTROS",
        value: "CantidadOperaciones",
        sortable: false,
        align: "center",
      },

      { text: "ACCIONES", value: "actions", sortable: false, align: "center" },
    ],

    rendiciones: [],
    loading: false,

    rowsPerPageArray: [10, 30, 50],
    rowsPerPage: 10, // cantidad de registros que se muestran en la tabla
    page: 1, // pagina actual de la tabla
    pageCount: 0, // cantidad de paginas

    dataExcelExport: [],
    band: false,

    formatDate,
    formatImport,
    formatImportFloat,
  }),

  // si el usuario cambia de cliente, volver a realizar la llamada a la api
  watch: {
    getCurrentClientId() {
      this.getData();
    },

    getCurrentAccountId() {
      this.getData();
    },
  },

  computed: {
    ...mapGetters([
      "getCurrentClientId",
      "getCurrentAccountId",
      "CLIENTE_CUENTA_DATA",
    ]),

    daysDifference() {
      return moment(this.fechaFin).diff(moment(this.fechaInicio), "days");
    },

    //Header de tabla General
    //Rendiciones y headersG
    headers() {
      // Verifica si existen valores en las columnas TotalRendidoSplit y PorcentajeSplit
      const mostrarTotalRendidoSplit = this.rendiciones.some(
        (rendicion) =>
          rendicion.TotalRendidoSplit !== 0 &&
          rendicion.TotalRendidoSplit !== ""
      );
      const mostrarPorcentajeSplit = this.rendiciones.some(
        (rendicion) =>
          rendicion.PorcentajeSplit !== 0 && rendicion.PorcentajeSplit !== ""
      );

      // Filtra los headers según si se deben mostrar o no esas columnas
      return this.headersG.filter((campo) => {
        if (campo.value === "TotalRendidoSplit" && !mostrarTotalRendidoSplit) {
          return false;
        }
        if (campo.value === "PorcentajeSplit" && !mostrarPorcentajeSplit) {
          return false;
        }
        if (mostrarTotalRendidoSplit) {
          this.band = true;
        } else {
          this.band = false;
        }
        return true;
      });
    },

    //Header de tabla de Detalle
    //headerPreviewD y previewData
    headerPreview() {
      // Reutiliza la lógica de headers() para determinar si se deben mostrar las columnas en headerPreviewD
      const mostrarTotalRendidoSplit = this.headers.some(
        (campo) => campo.value === "TotalRendidoSplit"
      );
      const mostrarPorcentajeSplit = this.headers.some(
        (campo) => campo.value === "PorcentajeSplit"
      );

      // Filtra los headersPreview según si se deben mostrar o no esas columnas
      let aux = this.headerPreviewD.filter((detalle) => {
        if (detalle.value === "ImporteSplit" && !mostrarTotalRendidoSplit) {
          return false;
        }
        if (detalle.value === "PorcentajeSplit" && !mostrarPorcentajeSplit) {
          return false;
        }

        return true;
      });

      if (!this.costoServicio) {
        aux = aux.filter((detalle) => detalle.value !== "CostoServicio");
      }

      return aux;
    },

    costoServicio() {
      return this.CLIENTE_CUENTA_DATA.cuenta.find(
        (c) => c.id == this.getCurrentAccountId
      ).costo_servicio;
    },
  },

  mounted() {
    this.$nextTick(() => this.getData());
  },

  methods: {
    closeModal() {
      this.RestriccionFecha = false;
    },

    async getData() {
      if (this.daysDifference > 31) {
        this.RestriccionFecha = true;
        return;
      }

      try {
        this.loading = true;
        this.menu1 = false;
        this.menu2 = false;

        const params = {
          FechaInicio: moment(this.fechaInicio).format("DD-MM-YYYY"),
          FechaFin: moment(this.fechaFin).format("DD-MM-YYYY"),
          ClienteId: this.getCurrentClientId,
          CuentaId: this.getCurrentAccountId,
        };

        // const tieneSplitCuentas = this.$store.state.CLIENTE_CUENTA_DATA.cuenta.some(
        //   (cuenta) =>
        //     cuenta.id === this.$store.state.cuenta_ID &&
        //     cuenta.split_cuentas === true &&
        //     cuenta.subcuentas_id
        // );

        let data;
        //Si es subcuenta hacer primer caso sino el otro
        // if (tieneSplitCuentas) {
        //   data = await reportesService.reporteRendicionesSubcuentas(params);
        // } else {
        data = await reportesService.reporteRendiciones(params);
        // }

        this.rendiciones = data?.data?.DetallesRendiciones || [];
        this.loading = false;
      } catch (error) {
        // console.error(error);
        this.loading = false;
      }
    },

    async downloadExcel(item) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Rendiciones");

      // Define el estilo para los encabezados
      const headerStyle = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "D3D3D3" }, // Fondo gris claro
        },
        font: {
          bold: true,
        },
        border: {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
        },
      };

      const titleStyle = {
        font: {
          bold: true,
          size: 12, // Tamaño de fuente menor para el título
        },
        alignment: {
          horizontal: "left", // Centrar el texto
        },
      };

      // Agregar título con estilo
      const titleRow = worksheet.addRow([`REPORTE RENDICIONES`]);
      titleRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.style = titleStyle;
      });

      // Crear una fila con "FECHA DE DEPÓSITO" en negrita
      // const fechaText = `FECHA DE DEPÓSITO: ${item.Fecha}`;
      // const fechaRow = worksheet.addRow([fechaText]);

      // // Aplicar estilo solo a "FECHA DE DEPÓSITO"
      // fechaRow.getCell(1).style = {
      //   font: { bold: true },
      //   alignment: { horizontal: "left" },
      // };

      // // Agregar el texto con el resto de la información (sin negrita)
      // fechaRow.getCell(2).value = item.Fecha;
      worksheet.addRow([`FECHA: ${item.Fecha}`]);

      worksheet.addRow([]); // Fila en blanco

      const mostrarImporteSplit = item.Rendiciones.some(
        ({ ImporteSplit }) => ImporteSplit !== 0 && ImporteSplit !== ""
      );
      const mostrarPorcentajeSplit = item.Rendiciones.some(
        ({ PorcentajeSplit }) => PorcentajeSplit !== 0 && PorcentajeSplit !== ""
      );

      // const mostrarPorcentajeSplit = false;
      // const mostrarImporteSplit = false;

      let HEADER_TABLE = [
        "CUENTA",
        "REFERENCIA",
        "CONCEPTO",
        "FECHA COBRO",
        "FECHA DEPOSITO",
        "IMPORTE COBRADO $",
        "COMISIÓN $",
        "IVA %",
      ];

      if (this.costoServicio) HEADER_TABLE.push("CARGO POR SERVICIO $");

      if (mostrarPorcentajeSplit) HEADER_TABLE.push("PORCENTAJE SPLIT $");
      if (mostrarImporteSplit) HEADER_TABLE.push("IMPORTE SPLIT $");

      HEADER_TABLE = HEADER_TABLE.concat([
        "IMPORTE DEPOSITADO $",
        "CANT BOLETAS COBRADAS",
      ]);

      if (this.CLIENTE_CUENTA_DATA.sujeto_retencion) {
        HEADER_TABLE.push("RETENCION $");
      }

      // Agregar encabezados con estilo
      const headerRow = worksheet.addRow(HEADER_TABLE);
      headerRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.style = headerStyle;
      });

      // Agregar datos
      item.Rendiciones.forEach((rendicion) => {
        const row = [
          rendicion.Cuenta,
          rendicion.Id,
          rendicion.Concepto,
          rendicion.FechaCobro,
          rendicion.FechaDeposito,
          formatImportFloat(rendicion.ImporteCobrado),
          formatImportFloat(rendicion.Comision),
          rendicion.Iva,
        ];

        if (this.costoServicio) {
          row.push(formatImportFloat(rendicion.CostoServicio));
        }

        if (mostrarPorcentajeSplit) {
          row.push(
            rendicion.PorcentajeSplit !== 0 && rendicion.PorcentajeSplit !== ""
              ? `${rendicion.PorcentajeSplit}%`
              : ""
          );
        }

        if (mostrarImporteSplit) {
          row.push(
            rendicion.ImporteSplit !== 0 && rendicion.ImporteSplit !== ""
              ? formatImportFloat(rendicion.ImporteSplit)
              : ""
          );
        }

        row.push(formatImportFloat(rendicion.ImporteDepositado));
        row.push(rendicion.CantidadBoletasCobradas);

        if (this.CLIENTE_CUENTA_DATA.sujeto_retencion) {
          row.push(
            rendicion.Retenciones != "0,00"
              ? formatImportFloat(rendicion.Retenciones)
              : "-"
          );
        }

        worksheet.addRow(row);
      });

      // Agregar devoluciones si existen
      if (item.Devoluciones) {
        worksheet.addRow([]);
        worksheet.addRow(["DESCUENTOS"]);
        const devolucionesHeader = this.headerDevolucion
          .map((h) => h.text.toUpperCase())
          .join(";");
        worksheet.addRow(devolucionesHeader.split(";"));

        item.Devoluciones.forEach((d) => {
          const devolucionesRow = [
            d.FechaDeposito,
            d.ReferenciaExterna,
            d.ImporteDepositadoLabel,
            d.Motivo,
          ];
          worksheet.addRow(devolucionesRow);
        });
      }

      // Agregar totales
      worksheet.addRow([]);
      worksheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "TOTAL COBRADO $",
        formatImportFloat(item.TotalCobrado, true),
      ]);
      worksheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "TOTAL COMISIONES $",
        formatImportFloat(item.TotalComision, true),
      ]);
      worksheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "TOTAL IVA $",
        formatImportFloat(item.TotalIva, true),
      ]);

      if (this.costoServicio) {
        worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "TOTAL DE CARGO POR SERVICIO $",
          formatImportFloat(item.TotalCostoServicio, true),
        ]);
      }

      if (item.TotalReversion != 0) {
        worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "TOTAL REVERTIDO $",
          formatImportFloat(item.TotalReversion, true),
        ]);
      }

      if (item.TotalRetGanancias !== "" && item.TotalRetGanancias !== "0,00") {
        worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Ret. Gcia RG 4622/2019 $",
          formatImportFloat(item.TotalRetGanancias),
        ]);
      }

      if (item.TotalRetIVA !== "" && item.TotalRetIVA !== "0,00") {
        worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Ret. IVA RG 4622/2019 $",
          formatImportFloat(item.TotalRetIVA),
        ]);
      }

      if (item.TotalRetIIBB !== "" && item.TotalRetIIBB !== "0,00") {
        worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Ret IIBB RG 202/2020 $",
          formatImportFloat(item.TotalRetIIBB),
        ]);
      }

      if (item.Devoluciones) {
        worksheet.addRow([
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "TOTAL DESCUENTO $",
          item.TotalDevolucion,
        ]);
      }

      worksheet.addRow([
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "TOTAL RENDIDO $",
        this.band &&
        item.TotalRendidoSplit !== "" &&
        item.TotalRendidoSplit !== 0
          ? formatImportFloat(item.TotalRendidoSplit, true)
          : formatImportFloat(item.TotalRendido, true),
      ]);

      // Ajustar el ancho de las columnas
      worksheet.columns.forEach((column, index) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const length = cell.value ? cell.value.toString().length : 8;
          if (length > maxLength) {
            maxLength = length;
          }
        });

        // Establecer el ancho mínimo y máximo para las columnas
        const minWidth = 8;
        const maxWidth = 18;

        if (index + 1 === 10) {
          column.width = 20; // Ancho específico para la columna J
        } else {
          column.width = Math.min(Math.max(maxLength, minWidth), maxWidth);
        }
      });

      // Generar el archivo y guardarlo
      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        this.getNombreArchivo(item.Nombre) // Usar la función para obtener el nombre del archivo
      );
    },

    showPreview(item) {
      this.previewData = {
        fecha: item.Fecha,
        items: item.Rendiciones,
        devoluciones: item.Devoluciones ? item.Devoluciones : [],
        totalCobrado: item.TotalCobrado,
        totalRendido: item.TotalRendido,
        totalRendidoSplit: item.TotalRendidoSplit ? item.TotalRendidoSplit : [],
        porcentajeSplit: item.PorcentajeSplit ? item.PorcentajeSplit : [],
        totalComision: item.TotalComision,
        totalIva: item.TotalIva,
        totalCostoServicio: item.TotalCostoServicio,
        totalReversion: item.TotalReversion,
        totalRetGanancias: item.TotalRetGanancias,
        totalRetIVA: item.TotalRetIVA,
        totalRetIIBB: item.TotalRetIIBB,
        totalDevolucion: item.TotalDevolucion,
      };

      const columnaRetencion = this.headerPreview.find(
        (h) => h.value === "Retenciones"
      );

      if (!columnaRetencion) {
        this.headerPreviewD = [
          ...this.headerPreviewD,
          { text: "Retencion", value: "Retenciones" },
        ];
      }

      //ocultar columna retencion en DETALLE si no es sujeto de retencion
      if (!this.CLIENTE_CUENTA_DATA.sujeto_retencion) {
        this.headerPreviewD = this.headerPreviewD.filter(
          (h) => h.value !== "Retenciones"
        );
      }

      this.preview = true;
    },

    reversionColor(item) {
      return item.Concepto === "Reversion" && "red lighten-5";
    },

    //Generador de PDF
    gereratePdf(tableRows, data, devoluciones) {
      let HEADERS = this.headerPreview.filter(
        (h) => h.value !== "Retenciones" && h.value != "CantidadBoletasCobradas"
      );

      HEADERS = HEADERS.map((h) => h.text.toUpperCase());

      const HEADERS_DEVOLUCION = [
        "FECHA DE DEPÓSITO",
        "REFERENCIA",
        "IMPORTE DEPOSITADO",
        "MOTIVO",
      ];

      // console.log(devoluciones);

      const content = [
        // ENCABEZADO
        // dataEncabezadoPDF(this.CLIENTE_CUENTA_DATA, data.nroReporte),
        {
          text: "REPORTE DE RENDICIONES",
          fontSize: 8,
          bold: true,
          margin: [0, 10, 0, 0],
        },
        {
          text: `Fecha: ${data.fecha}`,
          fontSize: 7,
          bold: true,
          alignment: "right",
        },
        // TABLA DE RENDICIONES

        {
          layout: "headerLineOnly",
          style: "tabla",
          table: {
            headerRows: 1,
            widths: HEADERS.map(() => "auto"),
            body: [HEADERS, ...tableRows],
          },
        },

        devoluciones.length > 0
          ? {
              text: "DESCUENTOS",
              fontSize: 8,
              bold: true,
              margin: [0, 30, 0, 0],
            }
          : {},

        devoluciones.length > 0
          ? {
              layout: "headerLineOnly",
              style: "tabla",
              table: {
                headerRows: 1,
                widths: HEADERS_DEVOLUCION.map(() => "auto"),
                body: [HEADERS_DEVOLUCION, ...devoluciones],
              },
            }
          : {},

        // TOTALES
        {
          text: `Total Cobrado: ${data.totalCobrado}`,
          style: "totales",
          margin: [0, 20, 0, 0],
        },
        {
          text: `Total Comision: ${data.totalComision}`,
          style: "totales",
        },
        {
          text: `Total Iva: ${data.totalIva}`,
          style: "totales",
        },
        this.costoServicio
          ? {
              text: `Cargo por servicio: ${data.totalCostoServicio}`,
              style: "totales",
            }
          : [],
        data.totalRevertido != 0
          ? {
              text: `Total Revertido: ${formatImportFloat(
                data.totalRevertido
              )}`,
              style: "totales",
            }
          : {},

        /* ---------------------------totales retenciones--------------------------- */
        data.totalRetGanancias !== "" && data.totalRetGanancias !== "0,00"
          ? {
              text: `Ret. Gcia RG 4622/2019: $ ${formatImportFloat(
                data.totalRetGanancias
              )}`,
              style: "totales",
            }
          : {},
        data.totalRetIVA !== "" && data.totalRetIVA !== "0,00"
          ? {
              text: `Ret. IVA RG 4622/2019: $ ${formatImportFloat(
                data.totalRetIVA
              )}`,
              style: "totales",
            }
          : {},
        data.totalRetIIBB !== "" && data.totalRetIIBB !== "0,00"
          ? {
              text: `Ret IIBB RG 202/2020: $ ${formatImportFloat(
                data.totalRetIIBB
              )}`,
              style: "totales",
            }
          : {},
        devoluciones.length > 0
          ? {
              text: `Total Descuento: $ ${data.totalDevolucion}`,
              style: "totales",
            }
          : {},
        /* TOTAL RENDIDO */
        {
          text: `Total Rendido: ${data.totalRendido}`,
          style: "totales",
        },
      ];

      const fontSizeTabla = this.band ? 5.5 : 6;

      // CREANDO PDF
      const docDefinition = {
        header: dataEncabezadoPDF(this.CLIENTE_CUENTA_DATA, data.nroReporte),
        content,
        images: {
          wee: `${window.location.origin}/assets/img/wee_logo.png`,
        },
        pageMargins: [35, 160, 20, 35],
        styles: {
          tabla: { fontSize: fontSizeTabla, margin: [0, 15, 0, 0] },
          totales: { fontSize: 9, bold: true, alignment: "right", margin: 5 },
          header: {
            fontSize: 7,
            alignment: "left",
            margin: [0, 5, 0, 0],
          },
        },
      };

      //console.log(docDefinition);

      //return pdfMake.createPdf(docDefinition);

      // DESCARGAR
      return pdfMake
        .createPdf(docDefinition)
        .download(this.getNombreArchivo(data.nombre));
    },

    previewPdf(tableRows, data, devoluciones) {
      this.gereratePdf(tableRows, data, devoluciones).getBlob((blob) => {
        // Paso 5: Convertir el Blob en una URL
        var pdfUrl = URL.createObjectURL(blob);

        // Paso 6: Mostrar el preview del PDF
        PDFObject.embed(pdfUrl, "#pdf-preview");
      });
    },

    downloadPdf(item) {
      // Verifica si se deben mostrar las columnas ImporteSplit y PorcentajeSplit
      const mostrarImporteSplit = item.Rendiciones.some(
        ({ ImporteSplit }) => ImporteSplit !== 0 && ImporteSplit !== ""
      );
      const mostrarPorcentajeSplit = item.Rendiciones.some(
        ({ PorcentajeSplit }) => PorcentajeSplit !== 0 && PorcentajeSplit !== ""
      );

      // const mostrarPorcentajeSplit = false;
      // const mostrarImporteSplit = false;

      // Construye las filas de la tabla condicionalmente
      const tableRows = item.Rendiciones.map((r) => {
        let row = [
          r.Cuenta,
          r.Id,
          r.Concepto,
          r.FechaCobro ? r.FechaCobro : "-",
          r.FechaDeposito,
          r.ImporteCobrado ? r.ImporteCobrado : "-",
          r.Comision ? r.Comision : "-",
          r.Iva ? r.Iva : "-",
          r.CostoServicio ? r.CostoServicio : "-",
          r.ImporteDepositado,
        ];

        if (mostrarPorcentajeSplit) {
          row.push(
            r.PorcentajeSplit !== 0 && r.PorcentajeSplit !== ""
              ? r.PorcentajeSplit + "%"
              : "-"
          );
        }
        if (mostrarImporteSplit) {
          row.push(
            r.ImporteSplit !== 0 && r.ImporteSplit !== "" ? r.ImporteSplit : "-"
          );
        }

        if (!this.costoServicio) {
          row.splice(8, 1);
        }

        return row;
      });

      // Tabla de devoluciones
      let tableDev = [];
      if (item.Devoluciones) {
        tableDev = item.Devoluciones.map((d) => [
          d.FechaDeposito,
          d.ReferenciaExterna,
          "$ " + d.ImporteDepositadoLabel,
          d.Motivo,
        ]);
      }

      // Datos adicionales para el PDF, con condiciones para totalRendidoSplit y porcentajeSplit
      const data = {
        fecha: item.Fecha,
        nroReporte: item.NroReporte,
        nombre: item.Nombre,
        totalCobrado: formatImportFloat(item.TotalCobrado),
        totalComision: formatImportFloat(item.TotalComision),
        totalIva: formatImportFloat(item.TotalIva),
        totalRevertido: item.TotalReversion,
        totalCostoServicio: formatImportFloat(item.TotalCostoServicio),
        totalRendido:
          this.band &&
          item.TotalRendidoSplit !== "" &&
          item.TotalRendidoSplit !== 0
            ? formatImportFloat(item.TotalRendidoSplit)
            : formatImportFloat(item.TotalRendido),
        porcentajeSplit: mostrarPorcentajeSplit ? item.PorcentajeSplit : "",
        totalRetGanancias: formatImportFloat(item.TotalRetGanancias),
        totalRetIVA: formatImportFloat(item.TotalRetIVA),
        totalRetIIBB: formatImportFloat(item.TotalRetIIBB),
        totalDevolucion: item.TotalDevolucion,
      };

      // Genera el PDF usando los datos y las tablas generadas
      this.gereratePdf(tableRows, data, tableDev);
    },

    getNombreArchivo(nombre) {
      return `${nombre.replaceAll(" ", "-").replaceAll(".", "-")}-RENDICION`;
    },
  },
};
</script>

<style scoded>
.v-tooltip__content {
  width: 135px;
}
</style>
